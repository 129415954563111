





















































































import { Component, Vue } from 'vue-property-decorator'
import AuthStore from '@/store/auth'
import { rdb } from '@/plugins/firebase'
import { version } from '../../../package.json'

@Component
export default class VersionIndex extends Vue {
  items = [
    {
      version: '2.2.2',
      date: '2021.4.9',
      texts: [
        '차량 유형 selectBox로 변경',
        '차량 번호 Rule 변경'
      ]
    },
    {
      version: '2.2.1',
      date: '2021.4.8',
      texts: [
        '차량유형 combobox 수정'
      ]
    },
    {
      version: '2.2.0',
      date: '2021.4.8',
      texts: [
        'LDT-300BS 파라메터 전송 추가'
      ]
    },
    {
      version: '2.1.4',
      date: '2021.1.29',
      texts: [
        '펌웨어 전송 사이즈 변경 오류 수정',
        '로그아웃시 알림 해제'
      ]
    },

    {
      version: '2.1.3',
      date: '2021.1.28',
      texts: [
        '업데이트 중 취소 할 수 있게 변경'
      ]
    },

    {
      version: '2.1.2',
      date: '2021.1.25',
      texts: [
        '모델관리 페이지 추가: 쓰지 않는 그룹 삭제 가능'
      ]
    },

    {
      version: '2.1.1',
      date: '2021.1.22',
      texts: [
        '그룹 지정 개선: 장치관리에서 바로 가능'
      ]
    },

    {
      version: '2.1.0',
      date: '2021.1.22',
      texts: [
        '그룹, 비고 추가',
        '특정 그룹 일괄 처리 기능 추가',
        '일괄 그룹, 비고 지정 기능 추가',
        '펌웨어 버전, 그룹명, 비고 검색 가능'
      ]
    },

    {
      version: '2.0.0',
      date: '2021.1.15',
      texts: [
        '도움말 추가'
      ]
    },

    {
      version: '0.2.3',
      date: '2021.1.14',
      texts: [
        '시스템 정보 추가'
      ]
    },

    {
      version: '0.2.2',
      date: '2021.1.14',
      texts: [
        '로그인 도메인 버그 픽스'
      ]
    },

    {
      version: '0.2.1',
      date: '2021.1.13',
      texts: [
        '알림 시스템 추가',
        '설명: 화면이 켜져있을 경우 알림 수신'
      ]
    },

    {
      version: '0.2.0',
      date: '2021.1.13',
      texts: [
        '업데이트 시스템 추가'
      ]
    },

    {
      version: '0.1.6',
      date: '2021.1.13',
      texts: [
        '모델 수량 버그 픽스',
        '감시목록 유형별로 분리'
      ]
    },

    {
      version: '0.1.5',
      date: '2021.1.12',
      texts: [
        '실제 수신서버 반영',
        '모델 개수 버그 픽스'
      ]
    },

    {
      version: '0.1.4',
      date: '2021.1.11',
      texts: ['페이징 버그 픽스']
    },

    {
      version: '0.1.3',
      date: '2021.1.8',
      texts: ['이력관리 추가']
    },

    {
      version: '0.1.2',
      date: '2021.1.6',
      texts: ['알림 기능 추가']
    },
    {
      version: '0.1.1',
      date: '2021.1.5',
      texts: ['new release']
    }
  ]

  version = version

  get xs () { return this.$vuetify.breakpoint.xs }

  get isDev () { return AuthStore.isDev }

  get system () {
    if (!AuthStore.system) return this.items[0]
    return AuthStore.system
  }

  reload () {
    location.reload()
  }

  async update () {
    rdb.ref('system').set(this.items[0])
  }
}
