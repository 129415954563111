



import { Vue, Component } from 'vue-property-decorator'
import VersionIndex from '@/components/version/index.vue'

@Component({ components: { VersionIndex } })
export default class ViewVersion extends Vue {
}
